<template>
  <div v-if="deletable" class="mt-1">
    <v-icon
      v-if="tableIcon"
      data-type="deleteIcon"
      left
      @click.stop="showConfirmationDialog = true"
      >mdi-delete</v-icon
    >
    <v-list-item v-else :disabled="disabled">
      <v-list-item-title>
        <a @click.stop="showConfirmationDialog = true" class="black--text">
          <v-card class="pa-2 mt-3 elevation-0 delete" data-type="deleteButton">
            <v-icon class="mr-2 mt-n1">mdi-delete</v-icon>
            {{ info.name }}
          </v-card>
        </a>
      </v-list-item-title>
    </v-list-item>
    <confirmationDialog
      :value="showConfirmationDialog"
      title="Notice"
      :message="language.componentLabel.msgDelete"
      @confirmation-ok="confirmDeletefield(...arguments)"
      maxWidth="290"
      :buttonsIDPrefix="currentTable.id + '_' + rowindex"
    ></confirmationDialog>

    <deleteRow
      v-if="confirmation && typeof row != 'undefined'"
      :row="row"
      :info="info"
      :result="result"
      :currentTable="currentTable"
      :confirmation="confirmation"
      @confirmation-ok="confirmation = false"
    ></deleteRow>
  </div>
</template>
<script>
import confirmationDialog from "@/commonComponents/confirmationDialog.vue";
import deleteRow from "@/commonComponents/deleteRow.vue";
//import noticeDialog from "@/commonComponents/noticeDialog.vue";
//const qs = require("querystring");
export default {
  components: { confirmationDialog /*, noticeDialog*/, deleteRow },
  data() {
    return {
      showConfirmationDialog: false,
      illegalDelateData: {},
      confirmation: false,
    };
  },
  props: [
    "info",
    "actionName",
    "currentTable",
    "row",
    "rowindex",
    "tableIcon",
    "result",
  ],
  computed: {
    deletable() {
      let deletable = true;
      if (typeof this.row != "undefined" && this.row.deletable == "false") {
        deletable = false;
      }

      return deletable;
    },
    disabled() {
      let disabled = false;
      if (
        typeof this.info.additional != "undefined" &&
        typeof this.info.additional.disabled != "undefined"
      ) {
        disabled = this.info.additional.disabled;
      }
      return disabled;
    },
  },
  methods: {
    confirmDeletefield(confirmation) {
      this.showConfirmationDialog = false;
      this.$emit("close-menu");
      if (confirmation) {
        //  let dialogSize = { width: 650, height: 350, title: "Notice" };
       this.confirmation = confirmation;
       if(this.$route.query.f2=='myOrganisationProject' || this.$route.query.f2=='projectAdministration'){ //in this case delete needs some change when user didnt close
           this.confirmation = Math.random();
        }
        this.$emit("delete-selected");
      }
    },
  },
};
</script>
<style scoped>
.delete:hover {
  background-color: #b82519 !important;
}
</style>